<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col1Items"
            itemText="codeName"
            itemValue="code"
            label="연/원료 구분"
            name="col1"
            v-model="searchParam.col1">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col2Items"
            itemText="codeName"
            itemValue="code"
            label="연료구분"
            name="col2"
            v-model="searchParam.col2">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="사용 연료/원료"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          selection="multiple"
          rowKey="col0"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" />
              <c-btn label="삭제" icon="remove" />
              <c-btn label="저장" icon="save" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      col1Items: [
        { code: '1', codeName: '연료' },
        { code: '2', codeName: '원료' },
      ],
      col2Items: [
        { code: '1', codeName: '액체연료연소' },
        { code: '2', codeName: '고체연료연소' },
      ],
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grid.columns = [
        {
          name: 'plantCd',
          field: 'plantCd',
          label: '사업장',
          align: 'center',
          type: 'plant',
          sortable: true,
          style: 'width: 150px',
        },
        {
          name: 'col1',
          field: 'col1',
          label: '연/원료 구분',
          align: 'center',
          type: 'select',
          style: 'width: 200px',
          comboItems: this.col1Items,
          sortable: true,
        },
        {
          name: 'col0',
          field: 'col0',
          label: '연/원료명칭',
          align: 'center',
          type: 'text',
          sortable: true,
        },
        {
          name: 'col2',
          field: 'col2',
          label: '연료구분',
          align: 'center',
          type: 'select',
          style: 'width: 200px',
          comboItems: this.col2Items,
          sortable: true,
        },
        {
          name: 'col3',
          field: 'col3',
          label: '발열량',
          align: 'center',
          sortable: true,
          style: 'width: 150px',
          type: 'number',
        },
        {
          name: 'col4',
          field: 'col4',
          label: 'CO2 배출계수<br>(tCO2/TJ)',
          align: 'center',
          sortable: true,
          style: 'width: 150px',
          type: 'number',
        },
        {
          name: 'col5',
          field: 'col5',
          label: 'CH4 배출계수<br>(tCH4/TJ)',
          align: 'center',
          sortable: true,
          style: 'width: 150px',
          type: 'number',
        },
        {
          name: 'col6',
          field: 'col6',
          label: 'N2O 배출계수<br>(tN2O/TJ)',
          align: 'center',
          sortable: true,
          style: 'width: 150px',
          type: 'number',
        },
        {
          name: 'col7',
          field: 'col7',
          label: '사용여부',
          align: 'center',
          sortable: true,
          type: 'check',
          style: 'width: 100px',
          true: 'Y',
          false: 'N',
        },
      ]
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col0: '나프타', 
          plantCd: '1000',
          col1: '1', 
          col2: '2', 
          col3: '29.4', 
          col4: '54.2', 
          col5: '0.023', 
          col6: '0.0004', 
          col7: 'Y', 
        },
        {
          col0: '프로판', 
          plantCd: '1000',
          col1: '1', 
          col2: '1', 
          col3: '21.4', 
          col4: '52.2', 
          col5: '0.061', 
          col6: '0.0002', 
          col7: 'Y', 
        },
        {
          col0: '중유', 
          plantCd: '1000',
          col1: '2', 
          col2: '2', 
          col3: '29.1', 
          col4: '54.5', 
          col5: '0.012', 
          col6: '0.0002', 
          col7: 'Y', 
        },
      ];
    },
  }
};
</script>
